import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ServiceBase } from '../infrastructure/service-base';
import { BackendService } from './backend.service';

export interface CrudClaims {
  see: boolean;
  add: boolean;
  change: boolean;
  delete: boolean;
}

export class RightsCrud implements CrudClaims {
  public add = false;
  public change = false;
  public delete = false;
  public see = false;
}

export interface Rights {
  violation: CrudClaims & {
    request: {
      accept: boolean;
      approval: boolean;
      reject: boolean;
    };
    change: {
      responsible: boolean;
      sanction: boolean;
      violators: boolean;
    };
  };
  statistics: {
    industrialSafety: boolean;
    details: boolean;
  };
  services: CrudClaims;
  user: CrudClaims;
  employee: CrudClaims;
  department: CrudClaims;
  camera: CrudClaims;
  rule: CrudClaims;
  zone: CrudClaims;
  'violation-sanction': CrudClaims;
}

export function isCRUDRights(rights: Rights[keyof Rights]): rights is CrudClaims {
  const keys: (keyof CrudClaims)[] = [
    'see',
    'add',
    'change',
    'delete'
  ];

  const rightsKeys = Object.keys(rights);

  return keys.every(i => rightsKeys.includes(i));
}

export interface AuthToken {
  access: string;
  refresh: string;
  'max-age': number;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService extends ServiceBase {
  private readonly MAX_RIGHTS_CACHE_SECONDS = 60; // relatively safe period

  private cachedRights: { timeStamp: number; rights: Rights };
  private pendingRightsRequest$: Observable<Rights>;

  public constructor(
    public backend: BackendService
    ) {
    super(backend);
    this.clearRights();
  }

  protected get object(): string {
    return 'auth';
  }

  public login(login: string, pass: string): Observable<AuthToken> {
    return this.makePostCall<AuthToken>('login', {
      username: login,
      password: pass
    });
  }

  public logout(): Observable<string> {
    return this.makePostCall<string>('logout').pipe(tap((_) => this.clearRights()));
  }

  public refreshAccessToken(): Observable<AuthToken> {
    return this.makePostCall<AuthToken>('refresh-token');
  }

  public getRights(): Observable<Rights> {
    if (this.cachedRights && this.cachedRights.rights && !this.pendingRightsRequest$) {
      const now = Date.now();
      const diff = now - this.cachedRights.timeStamp;

      const secToMs = (s: number) => s * 1000;
      const maxDiff = secToMs(this.MAX_RIGHTS_CACHE_SECONDS);

      if (diff > maxDiff) {
        return of(this.cachedRights.rights);
      }
    }

    if (this.pendingRightsRequest$) {
      return this.pendingRightsRequest$;
    }
    const data = new Subject<Rights>();

    this.pendingRightsRequest$ = this.pendingRightsRequest$ || data.asObservable();

    this.makeGetCall<Rights>('rights').subscribe(value => {
      this.cachedRights = {
        rights: value,
        timeStamp: Date.now()
      };

      this.pendingRightsRequest$ = null;
      data.next(value);
    }, error => {
      this.pendingRightsRequest$ = null;
      data.error(error);
    });

    return this.pendingRightsRequest$;
  }

  public getStatus(): Observable<number> {
    return this.makeGetCall('status');
  }

  public restorePassword(login: string) {
    return this.makeGetCall('restore', { login });
  }

  public clearRights() {
    this.cachedRights = {
      rights: null,
      timeStamp: Date.now()
    };
  }
}
