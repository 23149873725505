import { Injectable } from '@angular/core';
import { AuthService, AuthToken} from './auth.service';
import {map, mapTo, tap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {JwtHelperService} from '@auth0/angular-jwt';
import { LocalStorageService } from 'angular-web-storage';


export const ACCESS_TOKEN_KEY = 'access_token';

// eslint-disable-next-line no-shadow
export enum AuthenticatedStatus {
  notAuthenticated,
  authenticated,
  tokenExpired,
}


@Injectable({
  providedIn: 'root'
})
export class AuthTokenService {
  constructor(
    private authService: AuthService,
    private jwtHelper: JwtHelperService,
    private localStorage: LocalStorageService
  ) {}

  public login(login: string, pass: string): Observable<string> {
    return this.authService.login(login, pass).pipe(
      tap(this.storeTokens.bind(this)),
      mapTo('ok')
    );
  }

  public refreshAccessToken(): Observable<AuthToken> {
    return this.authService.refreshAccessToken()
      .pipe(map((tokens: AuthToken) => {
        this.storeTokens(tokens);
        return tokens;
      }));
  }

  public logout(): Observable<string> {
    return this.authService.logout().pipe(
      tap(this.removeAccessToken.bind(this))
    );
  }

  public getAuthenticatedStatus(): AuthenticatedStatus {
    const accessToken: string = this.getAccessToken();
    if (accessToken) {
      if (this.jwtHelper.isTokenExpired(accessToken)) {
        return AuthenticatedStatus.tokenExpired;
      } else {
        return AuthenticatedStatus.authenticated;
      }
    } else {
      return AuthenticatedStatus.notAuthenticated;
    }
  }

  public getAccessToken(): string {
    return this.localStorage.get(ACCESS_TOKEN_KEY);
  }

  public removeAccessToken(): void {
    this.localStorage.remove(ACCESS_TOKEN_KEY);
  }

  public storeTokens(tokens: AuthToken) {
    this.localStorage.set(ACCESS_TOKEN_KEY, tokens.access);
  }
}
