import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataObject } from './backend-caller.service';

@Injectable()
export abstract class BackendService {
  public abstract get<T>(object: string, method: string, params?: DataObject): Observable<T>;

  public abstract post<T, TIn = any>(object: string, method: string, data?: TIn, params?: DataObject): Observable<T>;

  public abstract put<T, TIn = any>(object: string, method: string, data?: TIn, params?: DataObject): Observable<T>;

  public abstract patch<T, TIn = any>(object: string, method: string, data?: TIn, params?: DataObject): Observable<T>;

  public abstract delete<T>(object: string, method: string, params?: DataObject): Observable<T>;
}
