import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BackendCallerService, DataObject } from './backend-caller.service';

@Injectable()
export class MoqBackendCallerService extends BackendCallerService {

  public get<T>(object: string, method: string, params: DataObject = {}): Observable<T> {
    return super.get(object, method, params);
  }

  public post<T, TIn = any>(object: string, method: string, data?: TIn, params: DataObject = {}): Observable<T> {
    return super.get(object, method, this.convertToDataObject(data));
  }

  public put<T, TIn = any>(object: string, method: string, data?: TIn, params: DataObject = {}): Observable<T> {
    return super.get(object, method, this.convertToDataObject(data));
  }

  public patch<T, TIn = any>(object: string, method: string, data?: TIn, params: DataObject = {}): Observable<T> {
    return super.get(object, method, this.convertToDataObject(data));
  }

  public delete<T>(object: string, method: string, params: DataObject = {}): Observable<T> {
    return super.get(object, method, params);
  }

  protected makeUrl(object: string, method: string): string {
    return `${this.backendUrl}/assets/mock-data/${object}-${method}.json`;
  }

  private convertToDataObject<TIn = any>(data: TIn): DataObject {
    const dataObject: DataObject = {};

    for (const key of Object.keys(data)) {
      dataObject[key] = JSON.stringify(data[key]);
    }

    return dataObject;
  }
}
