import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FormsModule } from '@angular/forms';
import { MatListModule } from '@angular/material/list';
import { RouterModule } from '@angular/router';
import { CssUrlPipe } from './css-url.pipe';
import { OriNavigationSidebarComponent } from './ori-page-template/ori-navigation-sidebar/ori-navigation-sidebar.component';
import { PageTemplateComponent } from './page-template/page-template.component';
import { FullnamePipe } from './page-template/page-title/fullname.pipe';
import { PageHeaderComponent } from './page-template/page-title/page-header.component';
import { UserMenuComponent } from './page-template/page-title/user-menu/user-menu.component';
import { ToastViewComponent } from './toasts/toast-view/toast-view.component';
import { ToastsComponent } from './toasts/toasts.component';
import { ORILogoComponent } from './ori-logo/ori-logo.component';
import { ORIFooterComponent } from './ori-page-template/ori-footer/ori-footer.component';
import { ORIHeaderComponent } from './ori-page-template/ori-header/ori-header.component';
import { ORIUserComponent } from './ori-page-template/ori-header/ori-user/ori-user.component';
import { ORIPageTemplateComponent } from './ori-page-template/ori-page-template.component';
import { InlineSVGModule } from 'ng-inline-svg';
import { DefaultAvatarComponent} from './default-avatar/default-avatar.component';
import { AvatarModule, AvatarSource } from 'ngx-avatar';

const avatarSourcesOrder = [AvatarSource.CUSTOM, AvatarSource.INITIALS];

@NgModule({
  declarations: [
    PageTemplateComponent,
    PageHeaderComponent,
    UserMenuComponent,
    ORILogoComponent,
    FullnamePipe,
    ORIPageTemplateComponent,
    ORIHeaderComponent,
    ORIFooterComponent,
    ORIUserComponent,
    CssUrlPipe,
    ToastsComponent,
    ToastViewComponent,
    OriNavigationSidebarComponent,
    DefaultAvatarComponent
  ],
  exports: [
    PageTemplateComponent,
    PageHeaderComponent,
    UserMenuComponent,
    ORILogoComponent,
    ORIPageTemplateComponent,
    CssUrlPipe,
    FullnamePipe,
    DefaultAvatarComponent
  ],
  imports: [
    CommonModule,
    MatToolbarModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    RouterModule,
    MatSidenavModule,
    MatListModule,
    MatSlideToggleModule,
    InlineSVGModule.forRoot(),
    FormsModule,
    AvatarModule.forRoot({
      sourcePriorityOrder: avatarSourcesOrder
    })
  ]
})
export class OriCommonModule {
}
