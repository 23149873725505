import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ObservedValueOf, OperatorFunction } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { BackendService } from './backend.service';
import { DynamicConfigProviderService } from './dynamic-config-provider.service';

export interface ErrorObject {
  description: string | null;
  code?: string | null;
  traceback?: string | null;
}

export interface BackendResponse<T> {
  payload?: T;
  error?: ErrorObject;
}

export interface DataObject {
  [key: string]: string | string[];
}

function isErrorResponse(res: unknown): res is HttpErrorResponse {
  return res instanceof HttpErrorResponse;
}

function getErrorText(errorObject: ErrorObject | null, statusCode: number): string | undefined {
  const messageByStatus = {
    400: 'Некорректный запрос',
    401: 'Вы не авторизованы',
    403: 'У вас недостаточно прав для совершения данного действия',
    404: 'По этому запросу данных не найдено',
    500: 'Внутренняя ошибка сервера'
  };
  const messageByCode = {
    user_not_found: 'Ваш пользователь был удален',
    user_inactive: 'Ваш пользователь был заблокирован',
    not_authenticated: 'Вы не авторизованы'
  };

  return errorObject?.description || messageByCode[errorObject?.code] || messageByStatus[statusCode];
}

export function defaultErrorHandler<T>(errorTitle = 'Произошла ошибка'): OperatorFunction<T, ObservedValueOf<Observable<never>> | T> {
  return catchError<T, Observable<never>>((response: HttpErrorResponse & BackendResponse<T> | ProgressEvent) => {
    const errorObject: ErrorObject = isErrorResponse(response) ? response.error.error : null;
    const errorText = isErrorResponse(response) ? getErrorText(errorObject, response.status) : '';
    const err = new Error(errorText);

    Swal.fire({
      title: errorTitle,
      html: String(errorText),
      type: 'error',
      heightAuto: false,
    });

    if (errorObject?.traceback) {
      err.stack = errorObject.traceback;
    }

    throw err;
  });
}

@Injectable()
export class BackendCallerService extends BackendService {

  protected readonly backendUrl: string;

  constructor(
    private http: HttpClient,
    configProvider: DynamicConfigProviderService
  ) {
    super();
    this.backendUrl = configProvider.backendUrl;
  }

  public get<T>(object: string, method: string, params: DataObject = {}): Observable<T> {
    const url = this.makeUrl(object, method);
    return this.http.get<BackendResponse<T>>(url, {
      params: new HttpParams({fromObject: params}),
      withCredentials: true
    }).pipe(map(value => value.payload));
  }

  public post<T, TIn = any>(object: string, method: string, data?: TIn, params: DataObject = {}): Observable<T> {
    const url = this.makeUrl(object, method);
    return this.http.post<BackendResponse<T>>(url, data, {
      params: new HttpParams({fromObject: params}),
      withCredentials: true
    }).pipe(map(value => value.payload));
  }

  public put<T, TIn = any>(object: string, method: string, data?: TIn, params: DataObject = {}): Observable<T> {
    const url = this.makeUrl(object, method);
    return this.http.put<BackendResponse<T>>(url, data, {
      params: new HttpParams({fromObject: params}),
      withCredentials: true
    }).pipe(map(value => value.payload));
  }

  public patch<T, TIn = any>(object: string, method: string, data?: TIn, params: DataObject = {}): Observable<T> {
    const url = this.makeUrl(object, method);
    return this.http.patch<BackendResponse<T>>(url, data, {
      params: new HttpParams({fromObject: params}),
      withCredentials: true
    }).pipe(map(value => value.payload));
  }

  public delete<T>(object: string, method: string, params: DataObject = {}): Observable<T> {
    const url = this.makeUrl(object, method);
    return this.http.delete<BackendResponse<T>>(url, {
      params: new HttpParams({fromObject: params}),
      withCredentials: true
    }).pipe(map(value => value?.payload));
  }

  protected makeUrl(object: string, method?: string): string {
    if (method) {
      return `${this.backendUrl}/${object}/${method}`;
    } else {
      return `${this.backendUrl}/${object}`;
    }
  }
}
