import {Observable} from 'rxjs';
import {DataObject} from '../services/backend-caller.service';
import {BackendService} from '../services/backend.service';

export abstract class ServiceBase {
  protected constructor(
    protected backend: BackendService
  ) {
  }

  protected abstract get object(): string;

  protected makeGetCall<T>(method: string, params: DataObject = {}, object: string = null): Observable<T> {
    return this.backend.get<T>(object || this.object, method, params);
  }

  protected makePostCall<T, TIn = any>(method: string, data?: TIn, params: DataObject = {},
                                       object: string = null): Observable<T> {
    return this.backend.post<T>(object || this.object, method, data, params);
  }

  protected makePutCall<T, TIn = any>(method: string, data?: TIn, params: DataObject = {},
                                      object: string = null): Observable<T> {
    return this.backend.put<T>(object || this.object, method, data, params);
  }

  protected makePatchCall<T, TIn = any>(method: string, data?: TIn, params: DataObject = {},
                                        object: string = null): Observable<T> {
    return this.backend.patch<T>(object || this.object, method, data, params);
  }

  protected makeDeleteCall<T>(method: string, params: DataObject = {}, object: string = null): Observable<T> {
    return this.backend.delete<T>(object || this.object, method, params);
  }
}
