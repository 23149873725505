import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import {AuthenticatedStatus, AuthTokenService} from '../services/auth-token.service';

@Injectable({
  providedIn: 'root'
})
export class NotAuthenticatedGuard implements CanActivate {
  public constructor(
    private router: Router,
    private authTokenService: AuthTokenService
  ) {
  }

  public canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return new Observable(subscriber => {
      const status = this.authTokenService.getAuthenticatedStatus();
      switch (status) {
        case AuthenticatedStatus.notAuthenticated: {
          subscriber.next(status === AuthenticatedStatus.notAuthenticated);
          subscriber.complete();
          break;
        }
        case AuthenticatedStatus.tokenExpired: {
          subscriber.next(false);
          subscriber.complete();
          break;
        }
        case AuthenticatedStatus.authenticated: {
          this.router.navigate(['']);
          break;
        }
      }
    });
  }
}
