import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthenticatedStatus, AuthTokenService } from '../services/auth-token.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticatedGuard implements CanActivate {

  public constructor(
    private router: Router,
    private authTokenService: AuthTokenService
  ) {
  }

  public canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return new Observable(subscriber => {
      const status = this.authTokenService.getAuthenticatedStatus();
      switch (status) {
        case AuthenticatedStatus.authenticated: {
          subscriber.next(true);
          subscriber.complete();
          break;
        }
        case AuthenticatedStatus.tokenExpired: {
          this.authTokenService.refreshAccessToken().pipe(catchError(_ => {
            this.authTokenService.logout()
              .subscribe(() => {
                this.router.navigate(['login']);
              });

            return of(false);
          }))
          .subscribe(_ => {
            subscriber.next(true);
            subscriber.complete();
          });
          break;
        }
        case AuthenticatedStatus.notAuthenticated: {
          this.router.navigate(['login']);
          break;
        }
      }
    });
  }
}
