import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { AuthTokenService } from '../services/auth-token.service';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, switchMap, take, filter } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private accessTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    public authTokenService: AuthTokenService,
    public jwtHelper: JwtHelperService,
    ) {
  }
  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.authTokenService.getAccessToken() && !request.url.includes('refresh-token')) {
      if (!this.jwtHelper.isTokenExpired(this.authTokenService.getAccessToken())) {
        return next.handle(this.addAuthHeader(request));
      } else {
        return next.handle(request).pipe(catchError(error => {
          if (error instanceof HttpErrorResponse && error.status === 401) {
            return this.handleUnathorizedError(request, next);
          } else {
            return throwError(error);
          }
        }));
      }
    }
    return next.handle(request);
  }

  private handleUnathorizedError(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.accessTokenSubject.next(null);

      return this.authTokenService.refreshAccessToken().pipe(
        switchMap((token: any) => {
          this.isRefreshing = false;
          this.accessTokenSubject.next(token.access);
          return next.handle(this.addAuthHeader(request));
        }));
    } else {
      return this.accessTokenSubject.pipe(
        filter(token => token !== null),
        take(1),
        switchMap(_ => {
          return next.handle(this.addAuthHeader(request));
        }));
    }
  }

  private addAuthHeader(request: HttpRequest<any>): HttpRequest<any> {
    const token = this.authTokenService.getAccessToken();
    return request.clone({
      setHeaders: {
        Authorization: `JWT ${token}`
      }
    });
  }
}
